/* eslint-disable no-console */
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Camera } from "./Camera/Camera";
import { Microphone } from "./Microphone/Microphone";
import { ScreenShared } from "./ScreenShared/ScreenShared";
import { LeaveSession } from "./Leave/Leave";
import ZoomClientContext from "../../context/zoom-context";
import { useContext, useEffect, useState } from "react";
import { Fullscreen } from "./FullScreen/Fullscreen";
import { UserRole } from "@/schemaTypes";
import { useUser } from "@/providers/useUser";
import ZoomMediaContext from "../../context/media-context";
import { MediaDevice } from "@zoom/videosdk";

interface propsInterface {}

export const CustomControls: React.FC<propsInterface> = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const mediaStream = zoomMediaContext?.mediaContext.mediaStream;
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);

  const [selectedDevices, setSelectedDevices] = useState<{
    camera: string;
    microphone: string;
    speaker: string;
  }>({
    camera: "",
    microphone: "",
    speaker: "",
  });

  const [audioInputDevices, setAudioInputDevices] = useState<MediaDevice[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDevice[]>(
    []
  );
  const [videoDevices, setVideoDevices] = useState<MediaDevice[]>([]);

  useEffect(() => {
    const loadDevices = async (): Promise<void> => {
      if (!mediaStream) return;

      try {
        setAudioInputDevices(mediaStream.getMicList());
        setAudioOutputDevices(mediaStream.getSpeakerList());
        setVideoDevices(mediaStream.getCameraList());

        const savedDevices = JSON.parse(
          localStorage.getItem("selectedDevices") || "{}"
        );

        const updatedDevices = {
          camera: savedDevices.camera || mediaStream.getActiveCamera() || "",
          microphone:
            savedDevices.microphone || mediaStream.getActiveMicrophone() || "",
          speaker: savedDevices.speaker || mediaStream.getActiveSpeaker() || "",
        };

        setSelectedDevices(updatedDevices);

        // Guardar la configuración actualizada
        localStorage.setItem(
          "selectedDevices",
          JSON.stringify({
            ...updatedDevices,
            isActiveCamera: savedDevices.isActiveCamera,
            isActiveMicrophone: savedDevices.isActiveMicrophone,
          })
        );
      } catch (error) {
        console.error("Error loading devices:", error);
      }
    };

    loadDevices();

    // Suscribirse al evento device-change
    if (zmClient) {
      zmClient.on("device-change", loadDevices);
      return () => {
        zmClient.off("device-change", loadDevices);
      };
    }
  }, [mediaStream, zmClient]);

  const handleMicSelect = async (deviceId: string): Promise<void> => {
    try {
      if (mediaStream) {
        await mediaStream.switchMicrophone(deviceId);
        setSelectedDevices((prev) => ({ ...prev, microphone: deviceId }));

        const savedDevices = JSON.parse(
          localStorage.getItem("selectedDevices") || "{}"
        );
        localStorage.setItem(
          "selectedDevices",
          JSON.stringify({
            ...savedDevices,
            microphone: deviceId,
          })
        );
      }
    } catch (error) {
      console.error("Error switching microphone:", error);
    }
  };

  const handleSpeakerSelect = async (deviceId: string): Promise<void> => {
    try {
      if (mediaStream) {
        await mediaStream.switchSpeaker(deviceId);
        setSelectedDevices((prev) => ({ ...prev, speaker: deviceId }));

        const savedDevices = JSON.parse(
          localStorage.getItem("selectedDevices") || "{}"
        );
        localStorage.setItem(
          "selectedDevices",
          JSON.stringify({
            ...savedDevices,
            speaker: deviceId,
          })
        );
      }
    } catch (error) {
      console.error("Error switching speaker:", error);
    }
  };

  const handleCameraSelect = async (deviceId: string): Promise<void> => {
    try {
      if (mediaStream) {
        await mediaStream.switchCamera(deviceId);
        setSelectedDevices((prev) => ({ ...prev, camera: deviceId }));

        const savedDevices = JSON.parse(
          localStorage.getItem("selectedDevices") || "{}"
        );
        localStorage.setItem(
          "selectedDevices",
          JSON.stringify({
            ...savedDevices,
            camera: deviceId,
          })
        );
      }
    } catch (error) {
      console.error("Error switching camera:", error);
    }
  };

  return (
    <Box
      id="custom-controls-1"
      h="100%"
      w="100%"
      position="relative"
      zIndex="10"
    >
      <Flex
        backgroundColor="#303036"
        border="1px solid #32323A"
        px="8px"
        pt={isMobile && !isMentorOrAdmin ? "14px" : "12px"}
        pb={isMobile && !isMentorOrAdmin ? "8px" : "22px"}
        alignItems="center"
        justifyContent="space-between"
        overflow="visible"
      >
        {zmClient?.getSessionInfo().isInMeeting && isMentorOrAdmin && (
          <Flex
            flex={1}
            justifyContent="center"
            alignItems="center"
            gap={4}
            maxW="100%"
          >
            <Box>
              <Microphone
                microphones={audioInputDevices}
                speakers={audioOutputDevices}
                selectedMicId={selectedDevices.microphone}
                selectedSpeakerId={selectedDevices.speaker}
                onMicSelect={handleMicSelect}
                onSpeakerSelect={handleSpeakerSelect}
              />
            </Box>

            <Box>
              <Camera
                devices={videoDevices}
                selectedId={selectedDevices.camera}
                onSelect={handleCameraSelect}
              />
            </Box>

            <Flex alignItems="center" gap={4}>
              <Box>
                <ScreenShared />
              </Box>
              {!isMobile && (
                <Box>
                  <LeaveSession />
                </Box>
              )}
            </Flex>
          </Flex>
        )}

        {zmClient?.getSessionInfo().isInMeeting && !isMentorOrAdmin && (
          <Flex alignItems="center">
            <Box w={"100%"} display={"flex"} justifyContent={"flex-end"}>
              <Fullscreen />
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
