/* eslint-disable no-console */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { Trans, useTranslation } from "react-i18next";

import ZoomContext from "@/components/LiveSessionsZoom/context/zoom-context";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import { decodeBase64 } from "@/components/LiveSessions/utils/base64";
import { useUser } from "@/providers/useUser";
import { UserState } from "@/providers/UserProvider";
import { useConnectedClient } from "@/components/LiveSessionsZoom/Hooks/useConnectedClient";
import { RegisterAttendanceDocument } from "../graphql/registerAttendanceZm.generated";
import { StoreUserLeftEventDocument } from "../graphql/StoreUserLeftEventZm.generated";
import { BackButton } from "@/components/PageContainer/BackButton";
import { LiveVideo } from "@/components/LiveSessionsZoom/LiveVideo/LiveVideo";
import { CustomControls } from "@/components/LiveSessionsZoom/LiveVideo/Controls/CustomControls";
import Chat from "@/components/LiveSessionsZoom/Chat/Chat";
import { ModalActiveAudio } from "@/components/LiveSessionsZoom/LiveVideo/ModalActiveAudio";
import { useZoomReconnection } from "./useZoomReconnection";
import { ConnectionStatus } from "./connection";
import { ConnectionStatusOverlay } from "./ConnectionStatusOverlay";
import ChakraTransitionContainer from "./ChakraTransitionContainer";
import ErrorBoundary from "./ErrorBoundary";
import { UserRole } from "@/schemaTypes";

type ParamsRoute = {
  liveSessionId: string;
  name: string;
  userId: string;
};

const PageStreaming: React.FC = () => {
  const { t } = useTranslation();
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const { liveSessionId, name: userName, userId } = useParams<ParamsRoute>();
  const { user: userData } = useUser();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { dataAuthJWT } = useConnectedClient();
  const { zmClient, clientState } = useContext(ZoomContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const [registerAttendance] = useMutation(RegisterAttendanceDocument);
  const [storeUserLeftEvent] = useMutation(StoreUserLeftEventDocument);
  const [videoKey, setVideoKey] = useState(0);

  const isSessionReady = useCallback(() => {
    if (!zmClient) return false;
    try {
      return (
        zmClient.getSessionInfo()?.isInMeeting &&
        zoomMediaContext?.statusZoom === "connected"
      );
    } catch (error) {
      console.warn("Error checking session state:", error);
      return false;
    }
  }, [zmClient, zoomMediaContext?.statusZoom]);

  // Error handling for async errors
  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      // Ignorar errores específicos de estado de reunión cerrada
      if (
        event.reason?.type === "IMPROPER_MEETING_STATE" &&
        event.reason?.reason === "closed"
      ) {
        console.warn("Sesión cerrada - Esperando reconexión");
        return;
      }
      console.error("Promesa rechazada no manejada:", event.reason);
    };

    const handleError = (event: ErrorEvent) => {
      // Solo registrar errores que no sean de estado de reunión
      if (
        !event.error?.message?.includes("IMPROPER_MEETING_STATE") &&
        !event.error?.message?.includes("closed")
      ) {
        console.error("Error global:", event.error);
      }
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);
    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
      window.removeEventListener("error", handleError);
    };
  }, []);

  const getUsernameHelper = useCallback(
    (encodedUserName?: string, user?: UserState): string => {
      if (encodedUserName) {
        return decodeBase64(encodedUserName).replace(/[-_]/g, " ");
      }

      if (user) {
        if (user.name && user.lastName) {
          return `${user.name} ${user.lastName}`;
        }
        if (user.name) {
          return user.name;
        }
        if (user.id) {
          return user.id;
        }
      }

      return "Anonymous";
    },
    []
  );

  const reconnectionState = useZoomReconnection({
    zmClient,
    sessionTopic: dataAuthJWT?.getJwtAuthLiveSession.session.channelName || "",
    sessionToken: dataAuthJWT?.getJwtAuthLiveSession.jwt || "",
    username: getUsernameHelper(userName, userData),
    onReconnected: () => {
      setVideoKey((prev) => prev + 1);
    },
  });

  const shouldShowLoading = useMemo(() => {
    const isConnecting = !isSessionReady() || zoomMediaContext?.loadingZoom;
    const hasConnectionIssue =
      reconnectionState.status !== ConnectionStatus.CONNECTED;
    return isConnecting || hasConnectionIssue;
  }, [isSessionReady, zoomMediaContext?.loadingZoom, reconnectionState.status]);

  // Attendance registration
  useEffect(() => {
    let isRegistered = false;

    const registerUserAttendance = async () => {
      if (!dataAuthJWT || !isSessionReady() || isRegistered) return;

      try {
        await registerAttendance({
          variables: {
            liveSessionsId: dataAuthJWT.getJwtAuthLiveSession.session.id,
            isPortal1: !!userName,
            dataPortal1Input: {
              userId: userId || "",
            },
          },
        });
        isRegistered = true;
      } catch (error) {
        console.error("Failed to register attendance:", error);
      }
    };

    const registrationInterval = setInterval(() => {
      if (isSessionReady() && !isRegistered) {
        registerUserAttendance();
      }
    }, 5000); // Intentar cada 5 segundos hasta que se registre exitosamente

    registerUserAttendance();

    return () => {
      clearInterval(registrationInterval);
      if (dataAuthJWT && isSessionReady()) {
        storeUserLeftEvent({
          variables: {
            storeUserLeftEventInput: {
              liveSessionId: liveSessionId || "",
            },
          },
        }).catch((error) => {
          console.warn("Error storing user left event:", error);
        });
      }
    };
  }, [
    dataAuthJWT,
    liveSessionId,
    userName,
    userId,
    registerAttendance,
    storeUserLeftEvent,
    isSessionReady,
  ]);

  // Audio modal handling
  useEffect(() => {
    if (clientState?.isAuth && isSessionReady()) {
      onOpen();
    }
  }, [zmClient, clientState?.isAuth, onOpen, isSessionReady]);

  // Force reconnection handling
  useEffect(() => {
    let forceReconnectTimeout: NodeJS.Timeout;

    if (shouldShowLoading) {
      forceReconnectTimeout = setTimeout(() => {
        console.warn("Estado de carga prolongado detectado - Forzando recarga");
        if (!isSessionReady()) {
          window.location.reload();
        }
      }, 30000);
    }

    return () => {
      clearTimeout(forceReconnectTimeout);
    };
  }, [shouldShowLoading, isSessionReady]);

  const handleManualReconnect = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ErrorBoundary>
      <Box
        position="relative"
        w="100%"
        h="100vh"
        overflowY={{ base: "auto", lg: "hidden" }}
      >
        <ChakraTransitionContainer
          isLoading={shouldShowLoading}
          overlay={
            <ConnectionStatusOverlay
              state={reconnectionState}
              zmClient={zmClient}
            />
          }
          bgColor="secondary.300"
        >
          <Box
            id="PageStreaming-p"
            h="100%"
            w="100%"
            p={{ base: "10px", sm: 10, "2xl": 20 }}
            bgColor="secondary.300"
            display="flex"
            flexDirection="column"
          >
            <Flex
              minHeight={{
                base: "100vh",
                lg: `calc(100vh - ${40}px)`,
              }}
              alignItems="stretch"
              w="100%"
              flexDirection={{ base: "column", lg: "row" }}
              gap={{ base: 0, lg: 5 }}
            >
              <Box
                id="video-container-PageStreaming"
                w={{ base: "100%", lg: "75%" }}
                h={{
                  base: "calc(100vh - 100px)",
                  sm: "calc(100vh - 80px)",
                  "2xl": "calc(100vh - 120px)",
                }}
                borderRadius="8px"
              >
                <Flex justify="space-between" h={{ base: "10%", lg: "5%" }}>
                  <BackButton dark={true} to={-1} />
                  <Flex gap="9px" align="baseline" hidden={isMobile}>
                    <Text color="neutral.200" variant="feedback">
                      {t("Connection problems?")}
                    </Text>
                    <Flex
                      gap="8px"
                      align="center"
                      cursor="pointer"
                      onClick={handleManualReconnect}
                    >
                      <Text color="error.400" variant="caption">
                        {t("Reconnect")}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  id="video-container-PageStreaming"
                  flexDirection="column"
                  h={{ base: "100%", lg: "95%" }}
                  borderRadius="8px"
                >
                  <Box flex={1} overflow="hidden" borderTopRadius="8px">
                    <LiveVideo key={videoKey} userID={userId || ""} />
                  </Box>

                  <Box
                    h={{
                      base: "15%",
                      sm: "12%",
                      "2xl": isMentorOrAdmin ? "10%" : "7%",
                    }}
                    zIndex={100}
                  >
                    <CustomControls />
                  </Box>

                  <Box
                    backgroundColor="#27272E"
                    color="shades.white"
                    px="16px"
                    pt={"23px"}
                    pb={"16px"}
                    display="flex"
                    flexDirection="row"
                    borderBottomRadius={"8px"}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      hidden={true}
                      color="shades.white"
                      fontWeight="semibold"
                      bgGradient="linear(to-r, #E5E5E9, #6A6A7A)"
                      bgClip="text"
                    >
                      <Trans>Live Class</Trans>
                    </Text>
                    <Heading as="h6" fontSize="18px">
                      {dataAuthJWT?.getJwtAuthLiveSession?.session.name}
                    </Heading>
                  </Box>
                </Flex>

                <Flex
                  gap="9px"
                  align="baseline"
                  pt={5}
                  justifyContent="center"
                  hidden={!isMobile}
                  id="mobile-reconnect"
                >
                  <Text color="neutral.200" variant="feedback">
                    {t("Connection problems?")}
                  </Text>
                  <Flex
                    gap="8px"
                    align="center"
                    cursor="pointer"
                    onClick={handleManualReconnect}
                  >
                    <Text color="error.400" variant="caption">
                      {t("Reconnect")}
                    </Text>
                  </Flex>
                </Flex>
              </Box>

              <Box
                id="Chat-PageStreaming"
                width={{ base: "100%", lg: "25%" }}
                h={{
                  base: "calc(100vh - 100px)",
                  sm: "calc(100vh - 80px)",
                  "2xl": "calc(100vh - 120px)",
                }}
                borderRadius="8px"
                mt={{ base: "223px", lg: 0 }}
              >
                <Chat key={videoKey} />
              </Box>
            </Flex>
          </Box>

          <ModalActiveAudio
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            stream={zoomMediaContext?.mediaContext.mediaStream}
          />
        </ChakraTransitionContainer>
      </Box>
    </ErrorBoundary>
  );
};

export default PageStreaming;
